import Header from './Header';
import FAQList from './Faqs';
import Footer from './Footer';
import stopwatch from '../Images/stopwatch.png';
import takeControl from '../Images/takeControl.png';
import proactive from '../Images/proactive.png';
import create from '../Images/Create.png';
import manage from '../Images/stopwatch 1 (3).png';
import share from '../Images/stopwatch 1 (4).png';
import access from '../Images/Get Access.png'
import config from '../Images/Configure Alert.png'
import save from '../Images/Save.png'
import notification from '../Images/notification 1.png'
import downArrow from '../Images/down-arrow.png'
import 'tailwindcss/tailwind.css';
import { useState } from 'react';

const boxShadowStyle = {
    boxShadow: '0 14px 0px 3px  #D0DAD9, 0 0 0 0 #D0DAD9',
};

const Landing = () => {
    const initialValues = { fullName: '', email: '', contact: '', message: '' };
    const [toggle, setToggle] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [formValues, setFormValues] = useState(initialValues);
    const [showWaiting, setShowWaiting] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    function handleScrollDown() {
        const element = document.getElementById('youtube');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error("Element to scroll into view not found.");
        }
    }

    const handleSmoothScroll = (event, sectionId) => {
        event.preventDefault();
        document.getElementById(sectionId).scrollIntoView({
            behavior: 'smooth'
        });
        setToggle(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        setShowWaiting(true);
        e.preventDefault();

        fetch(
            'https://analyticsliv.com/api/madkpiContact',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "fullName": formValues.fullName,
                    "email": formValues.email,
                    "contact": formValues.contact,
                    "message": formValues.message,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                setShowWaiting(false);
                setFormSubmit(true);
                setIsSubmit(true);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <>
            <section className='font-rbt'>
                <div className='banner max-sm:px-3 max-sm:pb-12  font-rbt bg-[#1C4645] border-b-[20px] border-[#D0DAD9] rounded-b-[60px] sticky sm:relative'>
                    <Header />
                    <div className='sm:p-[44px_40px_64px_40px] lg:px-8 lg:py-12 xl:px-20 xl:py-4'>
                        <div className='max-md:flex-col md:flex md:items-center md:justify-between 0 pb-8 max-xl:gap-4'>
                            <div className=' md:w-1/2 xl:w-2/5'>
                                <div className=''>
                                    <h1 className=' max-sm:text-3xl sm:text-4xl font-bold leading-[1.5em] pt-10 sm:pt-4 text-white mb-8 sm:mb-12 max-sm:text-center'>
                                        Supercharge your marketing campaigns with our campaign alert system!
                                    </h1>
                                    <p className='text-white text-start mb-12 max-sm:mb-6 text-lg font-normal sm:leading-8 max-sm:text-center'>
                                        Stay ahead of budget constraints and optimize your advertising efforts seamlessly. Take control of your marketing spend and maximize ROI with real-time alerts and insights.
                                    </p>
                                    <div className='flex max-sm:flex-col max-sm:items-center max-sm:gap-5 justify-start max-md:justify-evenly md:gap-8 xl:gap-24'>
                                        {/* <a href="https://adsalert.analyticsliv.com/login" target='_blank'>
                                            <button className="button text-lg font-medium bg-transperent border-2 text-white hover:bg-white hover:text-[#1C4645]  border-white max-sm:px-20 px-4 py-2 sm:w-32 xl:w-44 rounded-lg transition-all duration-300 ease-linear ">
                                                Sign up
                                            </button>
                                        </a> */}
                                        <a href="">
                                            <button onClick={(e) => handleSmoothScroll(e, 'Benefits')} className="button text-lg  font-medium bg-white text-[#1C4645] hover:bg-transparent border-2 border-white max-sm:px-[46px] px-4 py-2 xl:w-44 rounded-lg  hover:text-white transition-all duration-300 ease-linear">
                                                Why Choose us
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {formSubmit ? (<div id='form' className='xl:mr-8 max-md:pt-0 sm:w-1/2 sm:mx-auto xl:w-2/5 max-md:mt-8 max-sm:mt-4 relative max-sm:pb-0 flex items-center justify-center'>
                                <div className='max-md:mx-4 z-10 h-[480px] md:h-[500] xl:h-[450px] md:my-10 right-[10%] max-md:mt-4
                                             xl:w-[420px] border-[5px] rounded-lg border-[#FFFFFF] shadow-[0_5px_10px_0_rgba(0,0,0,0.25)] text-center flex flex-col justify-around items-center 
                                             px-3 md:px-7 py-2 md:py-5 bg-white'>
                                    <h2 className="md:text-xl text-[#1c4546]">Thank you for showing interest with us!</h2>
                                    <h2 className="font-semibold text-2xl p-4 md:p-8 border-dashed border-b-2 border-sky-200">We will get back to you shortly !!</h2>
                                    <img alt="Thankyou" className="w-64 mx-auto" src="https://storage.googleapis.com/website-bucket-uploads/static/Na_Dec_46.jpg" />
                                </div>
                            </div>)
                                : (
                                    showWaiting ? (
                                        <div id='form' className='p-5 md:p-7 lg:py-6 text-center  flex-col gap-3  border border-[#00000040] rounded-2xl xl:mr-8 max-md:pt-0 sm:w-1/2 sm:mx-auto xl:w-2/5 bg-white max-md:mt-8 max-sm:mt-4 relative max-sm:pb-0 flex items-center justify-center'>
                                            <div className='max-md:mx-4 z-10 h-[480px] md:h-[500px] xl:h-[450px] md:my-10 right-[10%] max-md:mt-4 xl:w-[420px] flex items-center justify-center'>
                                                <div className="loader border-t-4 border-b-4 border-blue-500 rounded-full w-16 h-16 animate-spin"></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div id='form' className='xl:mr-8 max-md:pt-0 sm:w-1/2 sm:mx-auto xl:w-2/5 max-md:mt-8 max-sm:mt-4 relative max-sm:pb-0 flex items-center justify-center'>

                                            <form
                                                onSubmit={handleSubmit}
                                                className='p-5 md:p-7 max-sm:mt-8 lg:py-6 text-center flex flex-col gap-3 bg-white  border border-[#00000040] rounded-2xl'>
                                                <div className='text-[#30486A] text-lg font-medium'>We Understand Its difficult to get DV360 Account and control the outcome</div>
                                                <div className='text-[#000000] text-3xl font-semibold'>Let us help you !!</div>
                                                <div className=' flex flex-col gap-3 items-center'>
                                                    <input type='name' required className='px-2 py-3 border border-[#30486A] rounded-xl w-full' placeholder='Full Name*'
                                                        id="fullName" name="fullName" value={formValues.fullName} onChange={handleChange} />
                                                    <input type='email' required className='px-2 py-3 border border-[#30486A] rounded-xl w-full' placeholder='Email*'
                                                        id="email" name="email" value={formValues.email} onChange={handleChange} />
                                                    <input type='tel' required className='px-2 py-3 border border-[#30486A] rounded-xl w-full' placeholder='Contact No*'
                                                        id="contact" name="contact" value={formValues.contact} onChange={handleChange} />
                                                    <textarea rows={4} className='px-2 py-3 border border-[#30486A] rounded-xl w-full' placeholder='Type Your Message'
                                                        id="message" name="message" value={formValues.message} onChange={handleChange} />
                                                    <button name='contact us' type='submit' className='text-[#1C4645] text-lg font-medium  hover:bg-[#1C4645] hover:text-white border border-[#1C4645] rounded-xl w-[170px] h-[44px] shadow-[#00000040] shadow-xl transition-all duration-300 ease-linear'>Contact Us</button>
                                                </div>
                                            </form>
                                        </div>
                                    )
                                )}
                        </div>
                        <div onClick={handleScrollDown} className='down rounded-full absolute max-[350px]:top-[96%] max-[390px]:top-[96%] max-[380px]:top-[96%] max-[426px]:top-[96%] top-[90%] sm:top-[94%] max-[350px]:left-[33%] max-[380px]:left-[35%] max-[640px]:top-[96%] left-[37%] md:left-[43%] lg:left-[46%] bg-[#1C4645] border-transparent px-8 py-10 sm:px-10 sm:py-10 cursor-pointer text-white shadow-bottom-2xl z-10  '
                            style={boxShadowStyle}>
                            <img alt='down arrow' src={downArrow} className='' />
                        </div>
                    </div>
                </div>
            </section>


            {/* Youtube */}


            <section id='youtube' className='youtube max-md:mt-20 x md:mt-24 xl:mt-16  font-rbt'>
                <div className='container mx-auto lg:p-12'>
                    <div className="flex items-center mx-5 justify-center">
                        <div className="video-container w-full  max-sm:px-2 md:w-4/5">
                            <iframe
                                src="https://www.youtube.com/embed/hE0zd1u4bvs?autoplay=1"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="w-full  aspect-video rounded-2xl"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>


            {/* Benefits */}


            <section id='Benefits' className='py-10 lg:pb-16  font-rbt'>
                <div className='text-center'>
                    <h1 className='text-4xl pb-8 font-bold'>Benefits</h1>
                </div>
                <div className='flex flex-wrap max-md:gap-5 p-3 justify-evenly text-[#1C4645]'>
                    <div className="relative md:w-[30%] xl:w-[25%] p-8 gap-5 border rounded-[30px] group overflow-hidden hover:shadow-lg hover:shadow-[#9ba7a7]">
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 left-0 w-2/3 h-2/3 border-t-4 border-l-4 border-[#00000012] rounded-tl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%)]"></div>
                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute bottom-0 right-0 w-2/3 h-2/3 border-b-4 border-r-4 border-[#00000012] rounded-br-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>

                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%)]"></div>

                        <div className="relative z-10 gap-5 flex flex-col">
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img
                                    src={stopwatch}
                                    alt='stopwatch'
                                    className='filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0'
                                />
                            </div>
                            <div className="text-2xl font-semibold text-center">Real Time</div>
                            <div className='text-center'>Get real time alerts based on data at line items level to be always in the know of your campaign performance</div>
                        </div>
                    </div>

                    <div className="relative md:w-[30%] xl:w-[25%] p-8 gap-5 border rounded-[30px] group overflow-hidden hover:shadow-lg hover:shadow-[#9ba7a7]">
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 left-0 w-2/3 h-2/3 border-t-4 border-l-4 border-[#00000012] rounded-tl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%)]"></div>
                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute bottom-0 right-0 w-2/3 h-2/3 border-b-4 border-r-4 border-[#00000012] rounded-br-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>

                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%)]"></div>

                        <div className="relative z-10 gap-5 flex flex-col">
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img src={takeControl} alt='takeControl'
                                    className='filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0'
                                />
                            </div>
                            <div className="text-2xl font-semibold text-center">Take Control</div>
                            <div className='text-center'>Keep a check on the budget expenditure with controlled alerts and always stay ahead of the game</div>
                        </div>
                    </div>
                    <div className="relative md:w-[30%] xl:w-[25%] p-8 gap-5 border rounded-[30px] group overflow-hidden hover:shadow-lg hover:shadow-[#9ba7a7]">
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 left-0 w-2/3 h-2/3 border-t-4 border-l-4 border-[#00000012] rounded-tl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%)]"></div>
                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute bottom-0 right-0 w-2/3 h-2/3 border-b-4 border-r-4 border-[#00000012] rounded-br-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>

                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%)]"></div>

                        <div className="relative z-10 gap-5 flex flex-col ">
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img src={proactive} alt='proactive'
                                    className='filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0'
                                />
                            </div>
                            <div className="text-2xl font-semibold text-center">Proactive</div>
                            <div className='text-center'>Be proactive in planning the performance of the campaigns in DV360 and stay in the know of it with data based alerts</div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Features */}


            <section id='Features' className='py-10 lg:py-14 xl:px-3 bg-[#1C4645] font-rbt'>
                <div>
                    <div className='text-center'>
                        <h1 className='text-4xl text-white pb-7 font-bold'>Features</h1>
                    </div>
                    <div className='flex flex-wrap justify-evenly xl:gap-8 gap-5 max-md:p-3 max-lg:p-2'>
                        <div className='h-[379px] w-[366px] bg-[#FFFFFF26] border border-[#F5F5F5] rounded-3xl flex flex-col p-8 gap-5 group hover:shadow-lg hover:shadow-[#5c5e5e]'>
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img src={create} alt='create'
                                    className='transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0'
                                />
                            </div>
                            <div className="text-3xl font-semibold text-white text-center">Proactive</div>
                            <div className='text-lg leading-6 font-normal text-white text-center '>Be proactive in planning the performance of the campaigns in DV360 and stay in the know of it with data based alerts</div>
                        </div>
                        <div className='h-[379px] w-[366px] bg-[#FFFFFF26] border border-[#F5F5F5] rounded-3xl flex flex-col p-8 gap-5 group hover:shadow-lg hover:shadow-[#5c5e5e] '>
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img src={manage} alt='manage'
                                    className='transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0'
                                />
                            </div>
                            <div className="text-3xl font-semibold text-white text-center">Manage</div>
                            <div className='text-lg leading-6 font-normal text-white text-center'>Colour code / copy your alert set up for managing them based on priority and criticality</div>
                        </div>
                        <div className='h-[379px] w-[366px] bg-[#FFFFFF26] border border-[#F5F5F5] rounded-3xl flex flex-col p-8 gap-5 group hover:shadow-lg hover:shadow-[#5c5e5e] '>
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img src={share} alt='share'
                                    className='transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0'
                                />
                            </div>
                            <div className="text-3xl font-semibold text-white text-center">Share</div>
                            <div className='text-lg leading-6 font-normal text-white text-center'>Share alerts with your team members and take them along for performance updates</div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Process */}


            <section id='Process' className='py-10 lg:pb-16 font-rbt'>
                <div className='text-center'>
                    <h1 className='text-4xl pb-8 font-bold text-center'>Process</h1>
                </div>
                <div className='flex flex-wrap max-md:gap-5 p-3 justify-evenly text-[#1C4645]'>
                    <div className="relative md:w-[30%] xl:w-[25%] p-8 gap-5 border rounded-[30px] group overflow-hidden hover:shadow-lg hover:shadow-[#9ba7a7]">
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 left-0 w-2/3 h-2/3 border-t-4 border-l-4 border-[#00000012] rounded-tl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%)]"></div>
                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute bottom-0 right-0 w-2/3 h-2/3 border-b-4 border-r-4 border-[#00000012] rounded-br-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>

                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%)]"></div>

                        <div className="relative z-10 gap-5 flex flex-col">
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img src={access} alt='access'
                                    className='filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0'
                                />
                            </div>
                            <div className="text-2xl font-semibold  text-center">Get Access</div>
                            <div className=' text-center'>Request MAdKPI access to use filters and create alerts</div>
                        </div>
                    </div>
                    <div className="relative md:w-[30%] xl:w-[25%] p-8 gap-5 border rounded-[30px] group overflow-hidden hover:shadow-lg hover:shadow-[#9ba7a7]">
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 left-0 w-2/3 h-2/3 border-t-4 border-l-4 border-[#00000012] rounded-tl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%)]"></div>
                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute bottom-0 right-0 w-2/3 h-2/3 border-b-4 border-r-4 border-[#00000012] rounded-br-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>

                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%)]"></div>

                        <div className="relative z-10 gap-5 flex flex-col">
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center">
                                <img src={config} alt='config'
                                    className='filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0'
                                />
                            </div>
                            <div className="text-2xl font-semibold text-center">Configure Alert</div>
                            <div className=' text-center'>Choose appropriate configuration for your alerts with relevant dimensions metrics, alert level.</div>
                        </div>
                    </div>
                    <div className="relative md:w-[30%] xl:w-[25%] p-8 gap-5 border rounded-[30px] group overflow-hidden hover:shadow-lg hover:shadow-[#9ba7a7]">
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 left-0 w-2/3 h-2/3 border-t-4 border-l-4 border-[#00000012] rounded-tl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute top-0 right-0 w-1/3 h-1/3 border-t-4 border-r-4 border-[#1C4645] rounded-tr-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%)]"></div>
                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>
                        <div className="absolute bottom-0 right-0 w-2/3 h-2/3 border-b-4 border-r-4 border-[#00000012] rounded-br-[30px] transition-all duration-1000 group-hover:w-full group-hover:h-full"></div>

                        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 border-b-4 border-l-4 border-[#1C4645] rounded-bl-[30px] transition-all duration-1000 group-hover:clip-path-[polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%)]"></div>

                        <div className="relative z-10 gap-5 flex flex-col ">
                            <div className="w-[120px] h-[120px] bg-[#1C4645] mx-auto border border-transparent rounded-[30px] flex justify-center items-center relative">
                                <img src={save} alt='save' className='absolute filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0' />
                                <img src={notification} alt='notification' className='absolute pb-3 filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0'
                                />
                                {/* className='filter brightness-200 invert transform translate-x-[-60px] transition-transform duration-300 ease-linear group-hover:translate-x-0 group-hover:brightness-100 group-hover:invert-0' */}

                            </div>
                            <div className="text-2xl font-semibold text-center">Save & Share Alert</div>
                            <div className=' text-center'>Choose the name and duration of alert with appropriate emails to receive notification when the condition is met</div>
                        </div>
                    </div>
                </div>
            </section>


            {/* FAQ's */}


            <section className='bg-[#1C4645] py-10 lg:py-8 xl:px-3 font-rbt'>
                <div className='text-center'>
                    <h1 className='text-4xl pb-0 text-white font-bold'>FAQ’s</h1>
                </div>
                <div className=''>
                    <FAQList />
                </div>
            </section>


            {/* Footer */}


            <section className='bg-[#1D1D1D] font-rbt'>
                <Footer />
            </section>

        </>
    )
}

export default Landing